<template>
  <v-speed-dial
    v-model="fab"
    direction="bottom"
    transition="slide-y-transition"
  >
    <template v-slot:activator>
      <v-btn color="primary" v-model="fab" dark fab small>
        <v-icon v-if="fab">mdi-close</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list dark dense id="menu__list">
      <template v-if="!isKiosk">
        <v-subheader>MENU</v-subheader>
        <ListItemGroupMenu />
        <v-divider />
      </template>
      <v-subheader>ACCOUNT</v-subheader>
      <ListItemGroupUser />
    </v-list>
  </v-speed-dial>
</template>
<script>
import { getIsKioskSetting } from "@/helpers";

export default {
  name: "UserMenu",
  components: {
    ListItemGroupUser: () =>
      import("@/components/userOrLogin/ListItemGroupUser.vue"),
    ListItemGroupMenu: () =>
      import("@/components/userOrLogin/ListItemGroupMenu.vue"),
  },
  data() {
    return {
      fab: false,
    };
  },
  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
  },
};
</script>

<style lang="sass" scoped>
#menu__list
  position: absolute
  right: 0
  top: 0

@media only screen and (min-width: 600px)
  #menu__list
    position: relative
</style>
